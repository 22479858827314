import * as React from "react";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { PhotoIcon } from "@heroicons/react/24/solid";

export interface IFileInput {
    readonly data: File;
    readonly size: number;
    readonly lastModified: number;
    readonly name: string;
    readonly type: string;
}

interface IProps {
    readonly label: string;
    readonly onFilesLoaded: (value: Array<IFileInput>) => void;
    readonly multiple?: boolean;
    readonly accept?: string;
}

export const FileUploader: React.FC<React.PropsWithChildren<IProps>> = ({ label, multiple = false, onFilesLoaded, accept }) => {
    const [t] = useTranslation();
    const onChange = useCallback(
        async (e: React.ChangeEvent<HTMLInputElement>) => {
            const uploadedFiles = e.target.files;
            const base64images: Array<IFileInput> = [];
            if (uploadedFiles === null) {
                return base64images;
            }
            for (let i = 0; i < uploadedFiles.length; i++) {
                const rawFile = uploadedFiles.item(i);
                if (rawFile === null) {
                    continue;
                }
                if (rawFile === null) {
                    continue;
                }
                const { size, lastModified, name, type } = rawFile;
                const input: IFileInput = {
                    data: rawFile,
                    size,
                    lastModified,
                    name,
                    type,
                };
                base64images.push(input);
            }
            onFilesLoaded(base64images);
        },
        [onFilesLoaded]
    );

    return (
        <div className="col-span-full">
            <label htmlFor="cover-photo" className="block text-sm font-medium leading-6 text-gray-900">
                {t("createJobPage.stage2.uploadSource")}
            </label>
            <div className="mt-2 flex justify-center rounded-lg border border-dashed border-gray-900/25 px-6 py-10">
                <div className="text-center">
                    <PhotoIcon aria-hidden="true" className="mx-auto h-12 w-12 text-gray-300" />
                    <div className="mt-4 flex text-sm leading-6 text-gray-600">
                        <label
                            htmlFor="file-upload"
                            className="relative cursor-pointer rounded-md bg-white font-semibold text-indigo-600 focus-within:outline-none focus-within:ring-2 focus-within:ring-indigo-600 focus-within:ring-offset-2 hover:text-indigo-500"
                        >
                            <span>{label}</span>
                            <input
                                id="file-upload"
                                name="file-upload"
                                type="file"
                                className="sr-only"
                                onChange={onChange}
                                multiple={multiple}
                                accept={accept}
                            />
                        </label>
                        <p className="pl-1">or drag and drop</p>
                    </div>
                    <p className="text-xs leading-5 text-gray-600">{t("createJobPage.stage2.uploadType")}</p>
                </div>
            </div>
        </div>
    );
};
